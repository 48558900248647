<template>
<!--nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" href="https://bulma.io">
            <img v-if="darkMode" src="@/assets/apple-touch-icon-120x120_white.png" alt="Forren" />
            <img v-else src="@/assets/apple-touch-icon-120x120.png" alt="Forren" />
        </a>

        <div class="navbar-item">
            <span class="icon-text custom-hover-primary" v-on:click="openLanguageSelectionModal">
                <span class="icon">
                    <i>
                        <b-icon icon="globe" class="is-clickable" />
                    </i>
                </span>
                <span>{{ $i18n.locale.split("-")[0].toUpperCase() }}</span>
            </span>
        </div>
    </div>
</nav-->
<b-navbar ref="navbar" :fixed-top="true" :transparent="true">
    <template #brand>
        <div :style="navbarMarginLeftStyle" />
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img v-if="darkMode" src="@/assets/apple-touch-icon-120x120_white.png" alt="Forren" />
            <img v-else src="@/assets/apple-touch-icon-120x120.png" alt="Forren" />
        </b-navbar-item>
    </template>

    <template #end>
        <div class="is-flex is-align-items-center">
            <b-navbar-item>
                <span class="icon-text custom-hover-primary" v-on:click="openLanguageSelectionModal">
                    <span class="icon">
                        <i>
                            <b-icon icon="globe" class="is-clickable" />
                        </i>
                    </span>
                    <span>{{ $i18n.locale.split("-")[0].toUpperCase() }}</span>
                </span>
            </b-navbar-item>
            <b-navbar-item v-if="$store.state.me != null">
                <avatar :key="JSON.stringify($store.state.me) + $store.state.myPhoto" :size="32"
                    :src="$store.state.myPhoto" :user="$store.state.me" />
            </b-navbar-item>
        </div>
        <div :style="navbarMarginRightStyle" />
    </template>
</b-navbar>
</template>

<script>
import LanguageSelectionModal from '@/components/LanguageSelectionModal.vue';

export default {

    computed: {
        darkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(darkMode) {
                this.$store.state.darkMode = darkMode;
            }
        },

        navbarMarginLeftStyle() {
            const margin = Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-left: ${margin}px;`
        },

        navbarMarginRightStyle() {
            const margin = Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-right: ${margin}px;`
        }
    },

    methods: {
        openLanguageSelectionModal() {
            this.$buefy.modal.open({
                parent: this,
                component: LanguageSelectionModal,
                hasModalCard: false,
                trapFocus: true,
                fullScreen: false
            })
        }
    }
}
</script>
