<template>
<section class="modal-card-body">
    <h1 class="title mb-5">{{ $t('chooseYourLanguage') }}</h1>
    <div class="columns">
        <div class="column" v-for="[locale, language] of Object.entries($store.state.LOCALES)" :key="language" :value="locale">
            <span class="custom-hover-primary is-clickable has-text-weight-bold" v-on:click="selected(locale)">
                {{ language }}
            </span>
        </div>
    </div>
</section>
</template>

<script>
export default {
    methods: {
        selected(locale) {
            this.$i18n.locale = locale;
            this.$emit("close");
        }
    }
}
</script>
