<template>
<div id="app" class="is-noscroll">
    <div class="is-fullheight">
        <navbar id="nav" v-on:setDarkMode="setDarkMode" />
        <router-view id="router" />
        <div class="notification is-info" v-if="$store.state.serviceWorkerUpdated">
            Updated service worker, refresh please
        </div>
    </div>
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    components: {
        navbar: NavBar
    },
    computed: {
        darkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(darkMode) {
                this.$store.state.darkMode = darkMode;
            }
        }
    },
    mounted() {
        this.darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
        this.setDarkMode();
        this.$nextTick(() => {
            this.onResize();
            window.addEventListener('resize', this.onResize);
        })
        if (!window.navigator.cookieEnabled) {
            alert("Cookies not allowed");
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.$store.state.windowWidth = window.innerWidth;
            this.$store.state.windowHeight = window.innerHeight;
        },

        async setDarkMode() {
            const id = "dynamicDarkModeCss";
            const el = document.getElementById(id);
            if (this.darkMode && el)
                return;
            if (this.darkMode) {
                const file = document.createElement("link");
                file.id = id;
                file.rel = "stylesheet";
                file.href = "/css/style_dark.css";
                document.head.appendChild(file);
            } else {
                if (el)
                    el.remove();
            }
        },

        themeChanged() {
            this.$store.state.app.getContext().then(r => {
                this.darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || r.app.theme != "default";
                this.setDarkMode().then(() => this.$store.state.theme = r.app.theme);
            });
        }
    }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('@/assets/generated/style.css') screen;
/*@import url('@/assets/generated/style_dark.css') screen and (prefers-color-scheme: dark);*/

/*@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap');
font-family: 'Plus Jakarta Sans', sans-serif;*/

#app {
    font-family: Comfortaa, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav a {
    font-weight: bold;
}

#nav a.router-link-exact-active {
    color: #1c79e4;
}

/* hide scrollbar */
element {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}
</style>
