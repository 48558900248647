import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store/index';

function getDefaultLocale(){
  let locales = Object.keys(store.state.LOCALES).filter(k => k.toLowerCase() == navigator.language.toLowerCase());
  if (locales.length == 0)
    locales = Object.keys(store.state.LOCALES).filter(k => k.toLowerCase().startsWith(navigator.language.slice(0,2)));
  if (locales.length == 0)
    return "en-GB";
  return locales[0];
}

const messages = {
  'en-GB': {
    headline: 'The simplest app that saves money.',
    recommendCreditCard: 'Recommend credit card',
    from: 'From',
    to: 'To',
    amount: 'Amount',
    total: 'Total',
    foreignCurrencyFees: 'Foreign currency fees %',
    mastercardBankFee: 'Mastercard® bank fee %',
    visaBankFee: 'VISA® bank fee %',
    showDetails: 'Show details',
    hideDetails: 'Hide details',
    xIsCheaperAsOf: '{cardName} is cheaper as of {date}',
    xIsCheaper: '{cardName} is cheaper',
    asOf: 'as of {date}',
    useXSinceYIsUnavailable: 'Use your {cardAvailable}, at least one of the selected currencies seems to be unavailable for {cardUnavailable}',
    bothCardsOfferSameEndAmount: 'Both Mastercard® and VISA® offer the same end amount',
    neitherMastercardNorVisaAreAvailable: 'Neither Mastercard® nor VISA® seem to be available for the selected currency combination',
    usingXWillSave: "Using {cardName} will save {amount} {currency}",
    consultYourBank: "Your bank(s) may use other exchange rates than the ones of Mastercard® and VISA®. Please consult your own bank(s) for a binding information.",
    featured: "Featured",
    allCurrencies: "All Currencies",
    aboutUs: 'About us'
  },
  'de-DE': {
    headline: 'Die einfachste App, die Geld spart.',
    recommendCreditCard: 'Empfiehl Kreditkarte',
    from: 'von',
    to: 'zu',
    amount: 'Betrag',
    total: 'Summe',
    foreignCurrencyFees: 'Fremdwährungsgebühren %',
    mastercardBankFee: 'Mastercard® Bankgebühr %',
    visaBankFee: 'VISA® Bankgebühr %',
    showDetails: 'Details einblenden',
    hideDetails: 'Details ausblenden',
    xIsCheaperAsOf: '{cardName} ist günstiger mit Stand vom {date}',
    xIsCheaper: '{cardName} ist günstiger',
    asOf: 'mit Stand vom {date}',
    useXSinceYIsUnavailable: 'Benutz {cardAvailable}, mindestens eine der gewählten Währungen scheint nicht bei {cardUnavailable} verfügbar zu sein',
    bothCardsOfferSameEndAmount: 'Sowohl Mastercard® als auch VISA® bieten den gleichen Endbetrag an',
    neitherMastercardNorVisaAreAvailable: 'Weder Mastercard® noch VISA® scheinen für die gewählte Währungskombination verfügbar zu sein',
    usingXWillSave: "{cardName} wird dir {amount} {currency} sparen",
    consultYourBank: "Ihre Bank/Banken kann/können andere Wechselkurse als die von Mastercard® und VISA® verwenden. Bitte konsultieren Sie Ihre eigene(n) Bank(en) für verbindliche Informationen.",
    featured: "Häufig verwendet",
    allCurrencies: "Alle Währungen",
    aboutUs: 'Über uns',
  },
  'tr-TR': {
    headline: 'Para tasarruf eden en basit app.',
    recommendCreditCard: 'Kredi kartı öner',
    from: 'Harcama yapılan para birimi',
    to: 'Bankanın para birimi',
    amount: 'Harcama tutarı',
    total: 'Toplam',
    foreignCurrencyFees: 'Dövizli harcama masrafları %',
    mastercardBankFee: 'Mastercard® banka masrafı %',
    visaBankFee: 'VISA® banka masrafı %',
    showDetails: 'Detayları göster',
    hideDetails: 'Detayları gizle',
    xIsCheaperAsOf: '{cardName} {date} itibariyle daha uygun',
    xIsCheaper: '{cardName} daha uygun',
    asOf: '{date} itibariyle',
    useXSinceYIsUnavailable: '{cardAvailable} kullanın, seçilen para birimlerinden en azından biri {cardUnavailable} için mevcut gözükmüyor',
    bothCardsOfferSameEndAmount: 'Mastercard® ve VISA® son tutarda aynı',
    neitherMastercardNorVisaAreAvailable: 'Ne Mastercard® ne de VISA® seçilen para birimi kombinasyonu için mevcut gözükmüyor',
    usingXWillSave: "{cardName} ile {amount} {currency} tasarruf edeceksiniz",
    consultYourBank: "Bankanız veya bankalarınız, Mastercard® ve VISA®'nınkilerden farklı döviz kurları kullanabilir. Lütfen kesin bilgi için kendi bankanızla/bankalarınızla iletişime geçin.",
    featured: "Sık kullanılanlar",
    allCurrencies: "Tüm para birimleri",
    aboutUs: 'Hakkımızda'
  },
  'fr-FR': {
      headline: 'L\'application la plus simple qui permet d\'économiser de l\'argent.',
      recommendCreditCard: 'Recommander la carte de crédit',
      from: 'De',
      to: 'À',
      amount: 'Montant',
      total: 'Total',
      foreignCurrencyFees: 'Frais de devise étrangère %',
      mastercardBankFee: 'Frais bancaires Mastercard® %',
      visaBankFee: 'Frais bancaires VISA® %',
      showDetails: 'Afficher les détails',
      hideDetails: 'Masquer les détails',
      xIsCheaperAsOf: '{cardName} est moins cher à partir du {date}',
      xIsCheaper: '{cardName} est moins cher',
      asOf: 'à partir du {date}',
      useXSinceYIsUnavailable: 'Utilisez votre {cardAvailable}, au moins l\'une des devises sélectionnées semble être indisponible pour {cardUnavailable}',
      bothCardsOfferSameEndAmount: 'Tant Mastercard® que VISA® offrent le même montant final',
      neitherMastercardNorVisaAreAvailable: 'Ni Mastercard® ni VISA® ne semblent être disponibles pour la combinaison de devises sélectionnée',
      usingXWillSave: 'L\'utilisation de {cardName} vous fera économiser {amount} {currency}',
      consultYourBank: "Votre ou vos banques peuvent utiliser d'autres taux de change que ceux de Mastercard® et VISA®. Veuillez consulter votre banque pour des informations contraignantes.",
      featured: "Fréquemment utilisé",
      allCurrencies: "Toutes les devises",
      aboutUs: "À propos de nous"
  }
}

Vue.use(VueI18n);

export default new VueI18n({
  locale: getDefaultLocale(),
  messages,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'tr-TR': function(choice, _choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      const isnum = /^\d+$/.test(choice.toString());

      if (choice === 0 || isnum) {
        return 0;
      }

      return 1;
    }
  }
})
